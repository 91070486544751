import { ethers } from 'ethers';
import { useContext, useEffect, useState } from 'react';
import { DappStateContext } from '../dapp/context/dappStateContext';

const axios = require('axios');

const initialState = {
  loadingToken: true,
  loadingMetadata: true,
};

const useAsset = () => {
  const dapp = useContext(DappStateContext);
  const [state, setState] = useState(initialState);

  const asset = dapp.state.assets[0];

  let creatorAddress;
  if (asset) {
    creatorAddress = ethers.utils.getAddress(asset.creator);
  }

  useEffect(() => {
    async function apiRequest(tokenURI) {
      try {
        tokenURI = tokenURI.replace('ar://', 'https://arweave.net/');
        const response = await axios.get(tokenURI);
        const data = response.data;

        setState((prev) => {
          return {
            ...prev,
            artistName: data.artist,
            creatorName: data.creator,
            tokenDescription: data.description,
            tokenMedia: data.image,
            tokenVideo: data.animation_url,
            tokenMediaURL: data.image.replace('ar://', 'https://arweave.net/'),
            tokenVideoURL: data.animation_url.replace('ar://', 'https://arweave.net/'),
            tokenName: data.name,
            loadingMetadata: false,
          };
        });
      } catch (err) {
        console.log(err);
      }
    }

    async function loadHarbergerToken() {
      try {
        const tokenId = asset?.tokenId;
        const assetOwner = await dapp.HTAXcontract.ownerOf(tokenId);
        // const ownerAddressENS = await this.props.provider.lookupAddress(assetOwner);
        const baseTaxValue = await dapp.HTAXcontract.baseTaxValues(tokenId);
        const foreclosure = await dapp.HTAXcontract.foreclosure(tokenId);
        const tokenURI = await dapp.HTAXcontract.tokenURI(tokenId);

        setState((prev) => {
          return {
            ...prev,
            assetForeclosure: asset.foreclosureTimestamp.toString(),
            assetPrice: asset.priceAmount.toString(),
            assetTaxAmount: asset.taxAmount.toString(),
            assetTotalDeposit: asset.totalDepositAmount.toString(),
            baseTaxValue: baseTaxValue.toString(),
            creatorAddress: creatorAddress,
            ownerAddress: ethers.utils.getAddress(assetOwner),
            foreclosure: foreclosure,
            tokenURI: tokenURI,
            loadingToken: false,
          };
        });

        apiRequest(tokenURI);
      } catch (err) {
        console.log(err);
      }
    }
    if (asset) {
      loadHarbergerToken();
    }
  }, [
    asset,
    creatorAddress,
    dapp.HTAXcontract,
    dapp.state.adminAddress,
    state.tokenURI,
    dapp.state.transactionSuccess,
  ]);

  return {
    dapp,
    asset: state,
  };
};

export default useAsset;
