import React, { useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Footer.module.sass";
import Group from "./Group";
import Image from "../Image";
import Form from "../Form";
import Theme from "../Theme";

const Footers = () => {
  return (
    <footer className={styles.footer}>
      <div className={cn("container", styles.container)}>
        <div className={styles.foot}>
          <div className={styles.copyright}>
            Copyright © 2022 Beets Holdings LLC. All rights reserved
          </div>
        </div>
      </div>
      <Theme />
    </footer>
  );
};

export default Footers;
