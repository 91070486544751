import cn from 'classnames';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './Header.module.sass';
import useAsset from "../../hooks/useAsset";

const nav = [
  // {
  //   url: '/euler-beats',
  //   title: 'Euler Beats',
  // },
  // {
  //   url: '/',
  //   title: 'Harberger Taxes',
  // },
];

const Headers = ({ state }) => {
  const [visibleNav, setVisibleNav] = useState(false);
  const { dapp } = useAsset();
  
  const handleConnectWallet = ()=>{
    dapp.selectWallet();
    setVisibleNav(false);
  };

  return (
    <header className={styles.header}>
      <div className={cn('container', styles.container)} style={{ paddingTop: "20px", paddingBottom: "20px"}} >
        <Link className={styles.logo} to={{ pathname: "https://beetsdao.com" }} target="_blank" rel="noopener noreferrer">
          <img
            className={styles.pic}
            src="/assets/logos/transparent.png"
            alt="BeetsDAO"
          />
        </Link>
        <Link className={styles.logo} to={{ pathname: "https://songaday.world/songadao" }} target="_blank" rel="noopener noreferrer">
          <img
            className={styles.pic}
            src="/assets/logos/songadao.png"
            alt="SongADAO"
          />
        </Link>
        <div className={cn(styles.wrapper, { [styles.active]: visibleNav })}>
          <nav className={styles.nav}>
            {nav.map((x, index) => (
              <Link
                className={styles.link}
                // activeClassName={styles.active}
                to={x.url}
                key={index}
              >
                {x.title}
              </Link>
            ))}
            <span
              className={cn('button-stroke button-small', styles.button)}
              to="/connect-wallet"
              onClick={handleConnectWallet}
            >
              {state.selectedAddress
                ? `${state.selectedAddress.substring(0, 6)}...${state.selectedAddress.slice(-4)}`
                : 'Connect wallet'}
            </span>
          </nav>
        </div>
        <span
          className={cn('button-stroke button-small', styles.button)}
          onClick={handleConnectWallet}
        >
          {state.selectedAddress
            ? `${state.selectedAddress.substring(0, 6)}...${state.selectedAddress.slice(-4)}`
            : 'Connect wallet'}
        </span>
        <button
          className={cn(styles.burger, { [styles.active]: visibleNav })}
          onClick={() => setVisibleNav(!visibleNav)}
        ></button>
      </div>
    </header>
  );
};

export default Headers;
