import moment from 'moment-timezone';
import React, { Component } from 'react';
import { DappStateContext } from '../../dapp/context/dappStateContext';
import { OPEN_SEA_BASE_URI } from '../../dapp/utils/constants';
import { convertToEth } from '../../dapp/utils/helpers';
import styles from './Provenance.module.sass';

class Provenance extends Component {
  static contextType = DappStateContext;

  constructor(props) {
    super(props);

    this.formatTime = this.formatTime.bind(this);
  }

  formatTime = (unixTime) => {
    if (!unixTime) return;
    const localtz = moment.tz.guess();
    return moment.unix(unixTime).tz(localtz).format('M/DD/YY h:mm A');
  };

  render() {
    const dapp = this.context;
    const eventLogs = dapp.state.eventLogs
      .filter((e) => e.name !== 'Approval')
      .reverse();
    const tokenEvents = eventLogs.filter(
      (e) =>
        e.args.tokenId.toNumber() === dapp.state.assets[0]?.tokenId?.toNumber()
    );

    return (
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Timestamp</th>
              {/* <th>Asset</th> */}
              <th>Event</th>
              <th>From</th>
              <th>To</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {tokenEvents.map((event, index) => {
              const timestamp = event.args.timestamp;
              // const tokenId = event.args.tokenId
              const name = event.name;
              const from = event.args.from;
              const to = event.args.to;
              const value = event.args.value;

              return (
                <tr key={index}>
                  <td>{timestamp && this.formatTime(timestamp.toString())}</td>
                  {/* <td>{tokenId.toNumber()}</td> */}
                  <td>{name}</td>
                  <td>
                    <a
                      className={styles.link}
                      href={OPEN_SEA_BASE_URI + from}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {dapp._minifyHash(from)}
                    </a>
                  </td>
                  <td>
                    <a
                      className={styles.link}
                      href={OPEN_SEA_BASE_URI + to}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {dapp._minifyHash(to)}
                    </a>
                  </td>
                  <td style={{ whiteSpace: 'nowrap' }}>
                    {value && `Ξ ${parseFloat(convertToEth(value.toString()))}`}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default Provenance;
