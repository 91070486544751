// COMPONENTS
import { AuctionHouse } from '@zoralabs/zdk';
import { ethers } from 'ethers';
import React from 'react';
import App from '../../App';
import { DappStateContext } from '../context/dappStateContext';
// CONTRACTS
import contractAddress from '../contracts/contract-address.json';
import HTAX_ARTIFACT from '../contracts/AlwaysNeverYours.json';
// STYLESHEETS
import '../stylesheets/Dapp.css';
import { Storage } from '../utils';
import {
  ENIGMA_TOKEN_CONTRACT_ADDRESS,
  ORIGINAL_OWNER,
} from '../utils/EB/constants';
import { ENIGMA_ABI } from '../utils/EB/EulerBeatsAbi';
import { HTAX_EVENT_ABI } from '../utils/HTAX/constants';

import Onboard from 'bnc-onboard'


// NETWORKS
const MAINNET_NETWORK_ID = '1';
const HARDHAT_NETWORK_ID = 1337;
const RINKEBY_NETWORK_ID = 4;

const INFURA_ID = '16adb0114eae4719ada3c9db5844de0d';


const wallets = [
  { walletName: "metamask", preferred: true },
  { walletName: "walletConnect", preferred: true, infuraKey: INFURA_ID }
]

export class Dapp extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = {
      assets: [],
      loadingContract: true,
      networkError: undefined,
      selectedAddress: undefined,
      transactionError: undefined,
      transactionPending: undefined,
      transactionSuccess: undefined,
      apiProvider: undefined,
      auction: undefined,
      auctionHouse: undefined,
    };

    this.loadHarbergerContract = this.loadHarbergerContract.bind(this);
    this.getHarbergerContract = this.getHarbergerContract.bind(this);
    this.createAuction = this.createAuction.bind(this);
    this.beginAuction = this.beginAuction.bind(this);
    this.createBid = this.createBid.bind(this);
    this.endAuction = this.endAuction.bind(this);
    this.cancelAuction = this.cancelAuction.bind(this);
    this.mintAsset = this.mintAsset.bind(this);
    this.setApproval = this.setApproval.bind(this);
    this.listAsset = this.listAsset.bind(this);
    this.depositTax = this.depositTax.bind(this);
    this.buyAsset = this.buyAsset.bind(this);
    this.reclaimAsset = this.reclaimAsset.bind(this);
    this.connectWallet = this._connectWallet.bind(this);
    this.selectWallet = this._selectWallet.bind(this);
    this.mintPrint = this.mintPrint.bind(this);
    this.getTrackSupply = this.getTrackSupply.bind(this);
    this.getTrackPrice = this.getTrackPrice.bind(this);

    this.state = this.initialState;

    this.onboard = Onboard({
      walletSelect:{
        wallets: wallets
      },
      dappId: process.env.BLOCKNATIVE_API_KEY,       // [String] The API key created by step one above
      networkId: 4,  // [Integer] The Ethereum network ID your Dapp uses.
      subscriptions: {
        network: network => this._checkNetwork(network),
        address: address => this._initialize(address),
        balance: balance => this.setState({ selectedBalance: balance }),
        wallet: wallet => {
          if (!wallet.provider){
            this._resetState()
          }
        }
      }
    });

    this.onboard.config( { darkMode: true } )
  }

  //this method will open the wallet selection for the user to select which wallet
  //they want to use, the callback will trigger '_connectWallet()' to finalize the action (through )
  async _selectWallet() {
    const selected = await this.onboard.walletSelect();
    if(selected){
      await this.onboard.walletCheck();
    }
  }
  // This method is run when the user clicks the Connect. It connects the
  // dapp to the user's wallet, and initializes it.
  async _connectWallet() {
    // To connect to the user's wallet, we have to run this method.
    // It returns a promise that will resolve to the user's address.
    const accounts = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });

    // Once we have the address, we can initialize the application.

    // First we check the network
    // if (!this._checkNetwork()) {
    //   return;
    // }

    this._initialize(accounts[0]);

    // We reinitialize it whenever the user changes their account.
    window.ethereum.on('accountsChanged', ([newAddress]) => {
      // `accountsChanged` event can be triggered with an undefined newAddress.
      // This happens when the user removes the Dapp from the "Connected
      // list of sites allowed access to your addresses" (Metamask > Settings > Connections)
      // To avoid errors, we reset the dapp state
      if (newAddress === undefined) {
        return this._resetState();
      }

      this._initialize(newAddress);
    });

    // We reset the dapp state if the network is changed
    window.ethereum.on('chainChanged', ([networkId]) => {
      this._resetState();
    });

    this._dismissNetworkError();
  }

  _resetState() {
    console.clear();
    this.setState(this.initialState);
    Storage.Local.remove('lastUsedAddress');
  }

  _checkNetwork(_network) {
    if(!_network){
      return
    }
    if (_network === RINKEBY_NETWORK_ID) {
      return true;
    }

    this.setState({
      networkError: 'Please connect MetaMask to Rinkeby Testnet',
    });

    return false;
  }

  // This method initializes the dapp
  async _initialize(userAddress) {
    if (userAddress == undefined){
      this._resetState()
      return
    }
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    // Gets the user's ETH balance using MetaMask as the provider
    let selectedBalance;
    if (userAddress) {
      selectedBalance = await provider.getBalance(userAddress);
    }

    // Set the user's address and balance to global state
    this.setState({
      selectedAddress: ethers.utils.getAddress(userAddress),
      selectedBalance: selectedBalance,
    });

    Storage.Local.put({
      key: 'lastUsedAddress',
      value: userAddress,
      expiry: -1,
    });

    // Then, we initialize ethers, fetch the token's data, and start polling
    // for the user's balance.

    // Fetching the token data and the user's balance are specific to this
    // sample project, but you can reuse the same initialization pattern.
    // this._initializeEthers();

    // this.loadHarbergerContract();
  }

  ensureDarkTheme() {
    const checkboxes = document.querySelectorAll('input[type=checkbox]');
    if (checkboxes[0].checked === false) {
      checkboxes[0].click();
    }
  }

  componentDidMount() {
    this.ensureDarkTheme();

    this.initApiProvider();
    if (window.ethereum) {
      const currentAddress = Storage.Local.get('lastUsedAddress');
      if (currentAddress) {
            this._connectWallet();
      } else {
        console.log("State => " + JSON.stringify(this.onboard.getState()))
      }

    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.transactionSuccess !== this.state.transactionSuccess &&
      this.state.apiProvider
    ) {
      this.loadHarbergerContract(this.state.apiProvider);
    }
  }

  initApiProvider = () => {
    const apiProvider = new ethers.providers.InfuraProvider(
      'rinkeby',
      INFURA_ID
    );
    this.setState({
      apiProvider,
    });
    this._initializeEthers(apiProvider);
    this.loadHarbergerContract(apiProvider);
  };

  async _initializeEthers(apiProvider) {
    // We first initialize ethers by creating a provider using window.ethereum
    // this._provider = new ethers.providers.Web3Provider(window.ethereum);

    this.EBcontract = new ethers.Contract(
      ENIGMA_TOKEN_CONTRACT_ADDRESS,
      ENIGMA_ABI,
      apiProvider
      // this._provider.getSigner()
    );

    this.HTAXcontract = new ethers.Contract(
      contractAddress.HarbergerAsset,
      HTAX_ARTIFACT.abi,
      apiProvider
      // this._provider.getSigner()
    );
  }

  async getTrackSupply(originalTokenId) {
    // console.log('contractInstance', this.EBcontract);
    return await this.EBcontract.seedToPrintsSupply(originalTokenId);
  }

  async getTrackPrice(supplyCount) {
    // console.log("contractInstance", this.EBcontract);
    return await this.EBcontract.getPrintPrice(supplyCount);
  }

  async mintPrint(originalTokenId, price) {
    // console.log("contractInstance", this.EBcontract);
    try {
      const transaction = await this.EBcontract.mintPrint(
        originalTokenId,
        ORIGINAL_OWNER,
        { value: price }
      );
      this._connectWallet();
      this.setState({
        transactionPending: true,
        transactionHash: transaction.hash,
        transactionSuccess: undefined,
        transactionError: undefined,
      });

      const receipt = await transaction.wait();
      // The receipt, contains a status flag, which is 0 to indicate an error.
      if (receipt.status === 0) {
        // We can't know the exact error that make the transaction fail once it
        // was mined, so we throw this generic one.
        throw new Error('Transaction Failed');
      }

      this._connectWallet();
      this.setState({
        transactionError: undefined,
        transactionPending: undefined,
        transactionSuccess: 'Mint Print',
      });
    } catch (error) {
      this._connectWallet();
      this.setState({
        transactionPending: undefined,
        transactionSuccess: undefined,
        transactionError: this._getRpcErrorMessage(error),
      });
    }
  }

  async loadHarbergerContract(apiProvider) {
    const contractAdmin = await this.HTAXcontract.admin();
    const contractBalance = await apiProvider.getBalance(
      contractAddress.HarbergerAsset
    );
    const assets = await this.HTAXcontract.assets(1);
    const taxPercentage = await this.HTAXcontract.TAX_PERCENTAGE();
    const baseInterval = await this.HTAXcontract.BASE_INTERVAL();
    const network = await apiProvider.getNetwork();
    const logs = await apiProvider.getLogs({
      address: contractAddress.HarbergerAsset,
      fromBlock: 0,
    });
    const iface = new ethers.utils.Interface(HTAX_EVENT_ABI);

    const eventLogs = [];
    logs.forEach((log, i) => {
      try {
        eventLogs.push(iface.parseLog(log));
      } catch (e) {
        console.log('failed to parse log: ', log);
      }
    });

    this.setState({
      adminAddress: ethers.utils.getAddress(contractAdmin),
      assets: [assets],
      contractAddress: ethers.utils.getAddress(contractAddress.HarbergerAsset),
      contractBalance: contractBalance.toString(),
      baseInterval: baseInterval.toString(),
      network: network,
      taxPercentage: taxPercentage.toString(),
      loadingContract: false,
      eventLogs: eventLogs,
    });

    console.log('Harberger Contract State:', this.state);
  }

  async getHarbergerContract() {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    return new ethers.Contract(
      contractAddress.HarbergerAsset,
      HTAX_ARTIFACT.abi,
      provider.getSigner()
    );
  }

  async createAuction(curatorAddress, tokenId) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(
      contractAddress.HarbergerAsset,
      HTAX_ARTIFACT.abi,
      provider.getSigner()
    );
    const auctionHouse = new AuctionHouse(provider.getSigner(), 4);
    const auctionHouseAccount = auctionHouse.auctionHouse.address;
    const duration = 172800;
    const reservePrice = 0;
    const curatorFeePercentage = 50;
    const auctionCurrency = '0x0000000000000000000000000000000000000000';

    try {
      const createAuctionTx = await auctionHouse.createAuction(
        tokenId,
        duration,
        reservePrice,
        curatorAddress,
        curatorFeePercentage,
        auctionCurrency,
        contractAddress.HarbergerAsset
      );
      this._connectWallet();
      this.setState({
        transactionPending: true,
        transactionHash: createAuctionTx.hash,
        transactionSuccess: undefined,
        transactionError: undefined,
      });

      const receipt = await createAuctionTx.wait();
      console.log('Auction Created', receipt);
      this._connectWallet();
      this.setState({
        transactionError: undefined,
        transactionPending: undefined,
        transactionSuccess: 'Create Auction',
        auction: receipt,
        auctionHouse: auctionHouse,
      });
    } catch (err) {
      this._connectWallet();
      this.setState({
        transactionPending: undefined,
        transactionSuccess: undefined,
        transactionError: this._getRpcErrorMessage(err),
      });
    }
  }

  async beginAuction(auctionId) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const auctionHouse = new AuctionHouse(provider.getSigner(), 4);

    try {
      const beginAuctionTx = await auctionHouse.setAuctionApproval(
        auctionId,
        true
      );
      this._connectWallet();
      this.setState({
        transactionPending: true,
        transactionHash: beginAuctionTx.hash,
        transactionSuccess: undefined,
        transactionError: undefined,
      });

      await beginAuctionTx.wait();
      this._connectWallet();
      this.setState({
        transactionError: undefined,
        transactionPending: undefined,
        transactionSuccess: 'Begin Auction',
      });
    } catch (err) {
      console.log(err.message.data);
    }
  }

  async createBid(auctionId, amount) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const auctionHouse = new AuctionHouse(provider.getSigner(), 4);

    try {
      const createBidTx = await auctionHouse.createBid(auctionId, amount);
      this._connectWallet();
      this.setState({
        transactionPending: true,
        transactionHash: createBidTx.hash,
        transactionSuccess: undefined,
        transactionError: undefined,
      });

      await createBidTx.wait();
      this._connectWallet();
      this.setState({
        transactionError: undefined,
        transactionPending: undefined,
        transactionSuccess: 'Create Bid',
      });
    } catch (err) {
      this._connectWallet();
      this.setState({
        transactionPending: undefined,
        transactionSuccess: undefined,
        transactionError: this._getRpcErrorMessage(err),
      });
    }
  }

  async endAuction(auctionId) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const auctionHouse = new AuctionHouse(provider.getSigner(), 4);

    try {
      const endAuctionTx = await auctionHouse.endAuction(auctionId);
      this._connectWallet();
      this.setState({
        transactionPending: true,
        transactionHash: endAuctionTx.hash,
        transactionSuccess: undefined,
        transactionError: undefined,
      });

      await endAuctionTx.wait();
      this._connectWallet();
      this.setState({
        transactionError: undefined,
        transactionPending: undefined,
        transactionSuccess: 'End Auction',
      });
    } catch (err) {
      this._connectWallet();
      this.setState({
        transactionPending: undefined,
        transactionSuccess: undefined,
        transactionError: this._getRpcErrorMessage(err),
      });
    }
  }

  async cancelAuction(auctionId) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const auctionHouse = new AuctionHouse(provider.getSigner(), 4);

    try {
      const cancelAuctionTx = await auctionHouse.cancelAuction(auctionId);
      this._connectWallet();
      this.setState({
        transactionPending: true,
        transactionHash: cancelAuctionTx.hash,
        transactionSuccess: undefined,
        transactionError: undefined,
      });

      await cancelAuctionTx.wait();
      this._connectWallet();
      this.setState({
        transactionError: undefined,
        transactionPending: undefined,
        transactionSuccess: 'Cancel Auction',
      });
    } catch (err) {
      this._connectWallet();
      this.setState({
        transactionPending: undefined,
        transactionSuccess: undefined,
        transactionError: this._getRpcErrorMessage(err),
      });
    }
  }

  async mintAsset(arweaveId, ipfsHash, creatorAddress, paymentAddress) {
    const contract = await this.getHarbergerContract();

    try {
      const transaction = await contract.mintAsset(
        arweaveId,
        ipfsHash,
        creatorAddress,
        paymentAddress
      );
      this._connectWallet();
      this.setState({
        transactionPending: true,
        transactionHash: transaction.hash,
        transactionSuccess: undefined,
        transactionError: undefined,
      });

      await transaction.wait();
      this._connectWallet();
      this.setState({
        transactionError: undefined,
        transactionPending: undefined,
        transactionSuccess: 'Mint Asset',
      });
    } catch (err) {
      this._connectWallet();
      this.setState({
        transactionPending: undefined,
        transactionSuccess: undefined,
        transactionError: this._getRpcErrorMessage(err),
      });
    }
  }

  async setApproval(tokenId) {
    const contract = await this.getHarbergerContract();

    try {
      const transaction = await contract.approve(
        contractAddress.HarbergerAsset,
        tokenId
      );
      this._connectWallet();
      this.setState({
        transactionPending: true,
        transactionHash: transaction.hash,
        transactionSuccess: undefined,
        transactionError: undefined,
      });

      await transaction.wait();
      this._connectWallet();
      this.setState({
        transactionError: undefined,
        transactionPending: undefined,
        transactionSuccess: 'Approve Contract',
      });
    } catch (err) {
      this._connectWallet();
      this.setState({
        transactionPending: undefined,
        transactionSuccess: undefined,
        transactionError: this._getRpcErrorMessage(err),
      });
    }
  }

  async listAsset(tokenId, amount) {
    const contract = await this.getHarbergerContract();

    try {
      const transaction = await contract.listAssetInWei(
        tokenId,
        amount
      );
      this._connectWallet();
      this.setState({
        transactionPending: true,
        transactionHash: transaction.hash,
        transactionSuccess: undefined,
        transactionError: undefined,
      });

      await transaction.wait();
      this._connectWallet();
      this.setState({
        transactionError: undefined,
        transactionPending: undefined,
        transactionSuccess: 'List Asset',
      });
    } catch (err) {
      this._connectWallet();
      this.setState({
        transactionPending: undefined,
        transactionSuccess: undefined,
        transactionError: this._getRpcErrorMessage(err),
      });
    }
  }

  async depositTax(tokenId, amount) {
    const contract = await this.getHarbergerContract();

    try {
      const transaction = await contract.depositTax(tokenId, {
        value: amount,
      });
      this._connectWallet();
      this.setState({
        transactionPending: true,
        transactionHash: transaction.hash,
        transactionSuccess: undefined,
        transactionError: undefined,
      });

      await transaction.wait();
      this._connectWallet();
      this.setState({
        transactionError: undefined,
        transactionPending: undefined,
        transactionSuccess: 'Deposit Tax',
      });
    } catch (err) {
      this._connectWallet();
      this.setState({
        transactionPending: undefined,
        transactionSuccess: undefined,
        transactionError: this._getRpcErrorMessage(err),
      });
    }
  }

  async buyAsset(tokenId, assetPrice) {
    const contract = await this.getHarbergerContract();

    try {
      const transaction = await contract.buyAsset(tokenId, {
        value: assetPrice,
      });
      this._connectWallet();
      this.setState({
        transactionPending: true,
        transactionHash: transaction.hash,
        transactionSuccess: undefined,
        transactionError: undefined,
      });

      await transaction.wait();
      this._connectWallet();
      this.setState({
        transactionError: undefined,
        transactionPending: undefined,
        transactionSuccess: 'Purchase Asset',
      });
    } catch (err) {
      this._connectWallet();
      this.setState({
        transactionPending: undefined,
        transactionSuccess: undefined,
        transactionError: this._getRpcErrorMessage(err),
      });
    }
  }

  async reclaimAsset(tokenId) {
    const contract = await this.getHarbergerContract();

    try {
      const transaction = await contract.reclaimAsset(tokenId);
      this._connectWallet();
      this.setState({
        transactionPending: true,
        transactionHash: transaction.hash,
        transactionSuccess: undefined,
        transactionError: undefined,
      });

      await transaction.wait();
      this._connectWallet();
      this.setState({
        transactionError: undefined,
        transactionPending: undefined,
        transactionSuccess: 'Reclaim Asset',
      });
    } catch (err) {
      this._connectWallet();
      this.setState({
        transactionPending: undefined,
        transactionSuccess: undefined,
        transactionError: this._getRpcErrorMessage(err),
      });
    }
  }

  _dismissNetworkError = () => {
    this.setState({ networkError: undefined });
  };

  _dismissTransactionPending = () => {
    this.setState({ transactionPending: undefined });
  };

  _dismissTransactionSuccess = () => {
    this.setState({ transactionSuccess: undefined });
  };

  _dismissTransactionError = () => {
    this.setState({ transactionError: undefined });
  };

  _getRpcErrorMessage(error) {
    if (error.data) {
      return error.data.message.split("'")[1];
    }

    if (error.message.includes('reverted')) {
      return error.message.split(',"data"')[0];
    }

    return error.message;
  }

  _minifyHash(address) {
    if (!address) return;
    const hashStart = address.substring(0, 6);
    const hashEnd = address.substring(address.length - 4, address.length);

    return `${hashStart}...${hashEnd}`;
  }

  render() {
    return (
      <DappStateContext.Provider value={this}>
        <App dapp={this} />
      </DappStateContext.Provider>
    );
  }
}
