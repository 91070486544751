import cn from 'classnames';
import { ethers } from 'ethers';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { Alert as BootstrapAlert } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import { convertToEth, convertCurrencyToWei } from '../../dapp/utils/helpers';
import Alert from '../Alert';
import Modal from '../Modal';
import styles from './Auction.module.sass';

class Auction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auctionId: undefined,
      visibleModalBid: false,
      finished: false,
    };

    this.createBid = this.createBid.bind(this);
    this.formatTime = this.formatTime.bind(this);
    this.timeRemaining = this.timeRemaining.bind(this);
    this.reserveAuctionIsDefined = this.reserveAuctionIsDefined.bind(this);
  }

  componentDidMount() {
    if (this.reserveAuctionIsDefined()) {
      this.timeRemaining(this.props.reserveAuction['expectedEndTimestamp']);
      this.interval = setInterval(
        () => this.timeRemaining(this.props.reserveAuction['expectedEndTimestamp']),
        1000
      );
    }
  }

  componentDidUpdate(prevProps) {
    // Watches for the transaction status to be "Pending" before closing the bid modal
    if (
      prevProps.transactionPending !== this.props.transactionPending &&
      this.props.transactionPending === true
    ) {
      this.hideVisibleModalBid();
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  reserveAuctionIsDefined = () => {
    return typeof this.props.reserveAuction !== "undefined";
  };

  createBid = (event) => {
    const { dapp } = this.props;
    event.preventDefault();
    var value = convertCurrencyToWei(this.bid.value);
    value = value === undefined ? 0 : value;
    dapp.createBid(this.props.reserveAuction['id'], value);
  };

  formatTime = (unixTime) => {
    if (!unixTime) return;
    const localtz = moment.tz.guess();
    return moment.unix(unixTime).tz(localtz).format('M/DD/YY @ h:mm A');
  };

  timeRemaining = (timestamp) => {
    if (!timestamp) return;

    var current = Math.floor(Date.now() / 1000);
    var remaining = timestamp - current;
    var days = Math.floor(remaining / (3600 * 24));
    var hrs = Math.floor((remaining % (3600 * 24)) / 3600);
    var mins = Math.floor((remaining % 3600) / 60);
    var secs = Math.floor(remaining % 60);

    // if (secs === 1 && this.state.secs === 1) {
    //   secs = 0;
    // }

    if (current < timestamp) {
      this.setState({ days, hrs, mins, secs });
    }
  };

  showVisibleModalBid = () => {
    this.setState({
      visibleModalBid: true,
    });
  };

  hideVisibleModalBid = () => {
    this.setState({
      visibleModalBid: false,
    });
  };

  render() {
    const { dapp, auctionStatus, auctionBids } = this.props;
    const asset = dapp.state.assets[0];

    const error = dapp.state.networkError || dapp.state.transactionError;

    let creatorAddress;
    if (asset) {
      creatorAddress = ethers.utils.getAddress(asset.creator);
    }
    const curatorAddress = dapp.state.adminAddress;
    const selectedAddress = dapp.state.selectedAddress;

    const auctionIsFinished = auctionStatus === 'Finished';

    const auctionId = this.reserveAuctionIsDefined()
      ? this.props.reserveAuction['id']
      : null;
    const auctionEnd = this.reserveAuctionIsDefined()
      ? this.formatTime(this.props.reserveAuction['expectedEndTimestamp'])
      : null;
    const reservePrice = this.reserveAuctionIsDefined()
      ? this.props.reserveAuction['reservePrice']
      : null;

    let currentPrice;
    if (auctionBids && auctionBids.length > 0) {
      const currentBid = auctionBids.find((bid) => bid.bidType === 'Active');
      if (currentBid) {
        currentPrice = convertToEth(currentBid.amount, 2);
      }
    }

    const isAdminOrCreator =
      selectedAddress === creatorAddress || selectedAddress === curatorAddress;

    return (
      <div className={isAdminOrCreator && auctionIsFinished ? 'mt-3' : ''}>
        <div className={styles.wrap}>
          {auctionId && (
            <div>
              <div>
                {auctionEnd && (
                  <div className="mb-3">
                    <div className={styles.info}>Auction ending on</div>
                    <div className={cn('my-2', styles.number)} style={{ color: "#c074ff" }}>{auctionEnd}</div>
                    <b className="time-remaining">
                      {this.state.secs !== undefined && (
                      `${this.state.days} days, ${this.state.hrs} hrs, ${this.state.mins} mins, ${this.state.secs} secs`)}
                    </b>
                  </div>
                )}
                {auctionStatus === 'Pending' && (
                  <div className={cn('mb-3', styles.info)}>
                    Waiting for Auction to Begin
                  </div>
                )}
                {currentPrice && (
                  <div className={cn('my-4', styles.price)}>
                    Winning Bid: <b style={{ color: "#48d8b5" }}>Ξ {currentPrice}</b>
                  </div>
                )}
                <div className={cn('mb-1', styles.reservePrice)}>
                  Reserve Price: <b>Ξ {reservePrice}</b>
                </div>
              </div>
            </div>
          )}
        </div>
        {auctionId && auctionStatus === 'Active' && (
          <div className={styles.btns}>
            <button
              className={cn('button', styles.button)}
              onClick={this.showVisibleModalBid}
              disabled={!selectedAddress}
            >
              Place A Bid
            </button>
          </div>
        )}

        <Modal
          visible={this.state.visibleModalBid}
          onClose={this.hideVisibleModalBid}
        >
          <form onSubmit={this.createBid}>
            <div className={styles.formItem}>
              <div className={styles.fieldset}>
                <CurrencyInput
                  className={styles.currencyInput}
                  prefix="Ξ "
                  decimalsLimit={4}
                  placeholder="Ξ 0.00"
                  ref={(input) => {
                    this.bid = input;
                  }}
                />
                <div className={styles.btns}>
                  <button className={cn('button', styles.button)} type="submit">
                    Submit Bid
                  </button>
                </div>
              </div>
            </div>
          </form>
          <BootstrapAlert className="mt-4" variant="warning">
            Your bid will be automatically refunded if another person outbids
            you.
          </BootstrapAlert>
          {error && (
            <div className="mt-4">
              <Alert size="small" message={error} />
            </div>
          )}
        </Modal>
      </div>
    );
  }
}

export default Auction;
